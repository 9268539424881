"use client";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger, Draggable } from "gsap/all";

const TestimonialCard = () => {
  useEffect(() => {
    // Register ScrollTrigger plugin
    gsap.registerPlugin(ScrollTrigger, Draggable);

    // Animate testimonial cards container
    gsap.fromTo(
      ".testimonial-cards",
      { x: "50vw" }, // Start from the center of the viewport
      {
        x: "-100%", // Slide out to the left
        duration: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".testimonial-cards",
          start: "top center",
          end: "+=200%", // Make the animation cover a longer scroll distance
          scrub: 2,
          markers: false,
        },
      }
    );

    Draggable.create(".testimonial-cards", {
      type: "x",
      bounds: ".parent-wrapper",
      inertia: true,
    });

    // Animate individual cards
    gsap.from(".testi-card", {
      y: 20,
      duration: 0.8,
      stagger: 0.3, // Delay between card animations
      scrollTrigger: {
        trigger: ".testimonial-cards",
        start: "top center",
        end: "+=200%", // Sync with the parent animation
        scrub: 2,
        markers: false,
      },
    });
  }, []);

  return (
    <div className="parent-wrapper">
      <div className="testimonial-cards">
        <div className="testi-card">
          <div className="testi_body">
            <div className="image">
              <img src="/testi1.avif" alt="" />
            </div>
            <div className="star">
              <img src="star.avif" alt="" />
            </div>
            <div className="testi_text">
              <p>
                "We struggled to find the right talent globally, but with their
                automated candidate ranking, we quickly identified top-notch
                candidates who perfectly fit our requirements."
              </p>
              <span className="u_name">Sarah Johnson</span>
              <span className="u_description">
                Small Business Owner, Sarah's Boutique
              </span>
            </div>
          </div>
        </div>
        <div className="testi-card card_2">
          <div className="testi_body">
            <div className="image">
              <img src="/testi2.avif" alt="" />
            </div>
            <div className="star">
              <img src="star.avif" alt="" />
            </div>
            <div className="testi_text">
              <p>
                "As a fast-growing startup, we needed an efficient way to find
                skilled professionals from various regions. This AI tool
                exceeded our expectations."
              </p>
              <span className="u_name">Luke Skywalker</span>
              <span className="u_description">
                Small Business Owner, Sarah's Boutique
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
