import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
// Import other components as needed
// import { AboutUs } from "./pages/AboutUs";
// import { Services } from "./pages/Services";
// import { ContactUs } from "./pages/ContactUs";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Uncomment and update the following routes as needed */}
        {/* <Route path="/about" element={<AboutUs />} /> */}
        {/* <Route path="/services" element={<Services />} /> */}
        {/* <Route path="/contact" element={<ContactUs />} /> */}
      </Routes>
    </Router>
  );
};
