import { AppRoutes } from "./Route";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./plugins/custom_css/main.css";
import AddBootstrap from "./AddBootstrap";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "System Binaries";
  }, []);
  return (
    <>
      <AddBootstrap />
      <AppRoutes />
    </>
  );
}

export default App;
