import React, { useEffect } from "react";
import TestimonialCard from "../components/Slider";
import FAQ from "../components/Faq";
import AOS from "aos";
import "aos/dist/aos.css";

export const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);
  return (
    <>
      {/* navbar start */}
      <nav className="navbar fixed-top navbar-expand-lg bg-body-white custom_nav">
        <div className="container-fluid">
          <a className="nav_logo" href="#">
            <img src="/svgviewer-output (1).svg" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item benefits">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="#Benefits"
                >
                  Benefits
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#testimonial">
                  Testimonials
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#blogs">
                  Blogs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq">
                  FAQs
                </a>
              </li>
              <li className="nav-item ms-auto">
                <a className="nav-link" href="#faq">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                  >
                    Get a FREE consultation
                  </button>
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="quote">
            <li className="nav-item">
              <button type="button" className="btn btn-outline-primary btn-sm">
                Get a FREE consultation
              </button>
            </li>
          </div> */}
        </div>
      </nav>
      {/* navbar end */}
      <section id="banner">
        <div className="container banner_content text-center">
          <div className="row">
            <div className="col-lg-7 col-sm-7">
              <div className="heading_and_supporting_text">
                <div className="top_heading">
                  <span className="badge rounded-pill text-bg-success">
                    Subscribe
                  </span>
                  Get inspired by our blog posts{" "}
                  <i className="bi bi-arrow-right"></i>
                </div>
                <div className="heading">
                  <h3>Simplify Your Business</h3>
                </div>
                <div className="heading">
                  <h3>Maximize Your Success</h3>
                </div>
                <div className="supporting_text">
                  <span>Your Vision. Our Innovation. Together, We Succeed</span>
                </div>
                <div className="social_trust">
                  <div className="avatar-group">
                    <div className="avatar">
                      <span className="avatar-name">Someone 1</span>
                      <img
                        src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                        alt="Image"
                      />
                    </div>
                    <div className="avatar">
                      <span className="avatar-name">Someone 2</span>
                      <img
                        src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                        alt="Image"
                      />
                    </div>
                    <div className="avatar">
                      <span className="avatar-name">Someone 3</span>
                      <img
                        src="https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                        alt="Image"
                      />
                    </div>
                    <div className="avatar">
                      <span className="avatar-name">Someone 4</span>
                      <img
                        src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                        alt="Image"
                      />
                    </div>
                  </div>
                  <div className="stars">
                    <img src="/rating.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-sm-5">
              <div className="contact-form-wrapper d-flex justify-content-center">
                <form action="#" className="contact-form">
                  <h5 className="title">Contact with us now!</h5>
                  <p className="description">
                    Take the first step. Your journey starts today, seize the
                    moment
                  </p>
                  <div>
                    <input
                      type="text"
                      className="form-control rounded border-white mb-3 form-input"
                      id="name"
                      placeholder="Your name"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className="form-control rounded border-white mb-3 form-input"
                      placeholder="Your email"
                      required
                    />
                  </div>
                  <div className="btn btn-primary form_btn">Get in touch</div>
                  <p className="frm_bottom">We don't share data with anyone</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 benefits_top d-flex justify-content-center text-center">
              <div
                className="heading_and_supporting"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className="Benefits_heading">
                  <h3>Say goodbye to complexity, hello to simplicity.</h3>
                </div>
                <div className="supporting">
                  <p>
                    Say farewell to the complexities of the past and unlock a
                    smoother path to success. Welcome to a new era of simplicity
                    and efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="row benefits_bottom mt-5">
              <div className="col-lg-4 col-sm-4">
                <div className="card">
                  <div className="header">
                    <img src="/card_logo.png" alt="" />
                  </div>
                  <div className="content">
                    <p>Optimize your daily operations</p>
                  </div>
                  <div className="support_text">
                    <p>
                      Our software simplifies complex business processes,
                      helping you efficiently manage tasks, projects, and
                      resources.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="card">
                  <div className="header">
                    <img src="/card_logo.png" alt="" />
                  </div>
                  <div className="content">
                    <p>Make informed business decision</p>
                  </div>
                  <div className="support_text">
                    <p>
                      Our software provides comprehensive analytics, empowering
                      you to identify trends, track performance, and adjust your
                      strategies for success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="card">
                  <div className="header">
                    <img src="/card_logo.png" alt="" />
                  </div>
                  <div className="content">
                    <p>Scale your startup with confidence</p>
                  </div>
                  <div className="support_text">
                    <p>
                      Our software is designed to grow alongside your business,
                      adapting to your evolving needs. Scale your startup with
                      confidence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonial">
        <div className="container">
          <div className="testi_heading text-center">
            <h2>
              <span>Real stories.</span>Don’t take our word for it.
            </h2>
          </div>
          <div className="arrow">
            <img src="/arrow.avif" alt="" />
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="">
            <TestimonialCard />
          </div>
          <div
            className="div text-center"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="btn btn-outline-primary testi_btn">
              Read all stories
            </div>
          </div>
        </div>
        <section id="metrics">
          <div className="container">
            <div
              className="heading_metrics"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <h5>You can bet on it</h5>
            </div>
            <div className="row" data-aos="fade-up" data-aos-duration="2000">
              <div className="col-lg-4 col-sm-4 col-4">
                <p className="matrics_para bdr">
                  <span>75%</span>Customer satisfaction rate
                </p>
              </div>
              <div className="col-lg-4 col-sm-4 col-4">
                <p className="matrics_para bdr">
                  <span>40+</span>Successful projects
                </p>
              </div>
              <div className="col-lg-4 col-sm-4 col-4">
                <p className="matrics_para">
                  <span>50+</span>Operating countries
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="blogs">
        <div className="container">
          <div
            className="blog_heading mb-5"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <h5>Explore our blog for industry updates</h5>
          </div>
          <div className="row mb-5 blog_content">
            <div className="col-lg-4 col-sm-6 blogs">
              <div className="img">
                <img src="/blog_logo.jpg" alt="" />
              </div>
              <div className="content">
                <span>AI–powered editor</span>Assign tasks, set priorities, and
                track progress effortlessly
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 blogs">
              <div className="img">
                <img src="/blog_logo.jpg" alt="" />
              </div>
              <div className="content">
                <span>Tools integrations</span>Visualize trends and monitor key
                performances
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 blogs">
              <div className="img">
                <img src="/blog_logo.jpg" alt="" />
              </div>
              <div className="content">
                <span>Data analytics</span>Customize workflows, add
                integrations, and adapt features
              </div>
            </div>
          </div>
          <div className="row blog_content">
            <div className="col-lg-4 col-sm-6 blogs">
              <div className="img">
                <img src="/blog_logo.jpg" alt="" />
              </div>
              <div className="content">
                <span>Intuitive Task Management</span>Assign tasks, set
                priorities, and track progress effortlessly
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 blogs">
              <div className="img">
                <img src="/blog_logo.jpg" alt="" />
              </div>
              <div className="content">
                <span>Advanced Data Analytics</span>Visualize trends and monitor
                key performances
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 blogs">
              <div className="img">
                <img src="/blog_logo.jpg" alt="" />
              </div>
              <div className="content">
                <span>Scalability and Customization</span>Customize workflows,
                add integrations, and adapt features
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq">
        <div className="container">
          <div className="faq_heading">
            <h2>
              <span>Still not convinced?</span> We’ve got the answers
            </h2>
          </div>
          <div className="faq_cntnt">
            <FAQ />
          </div>
          <div className="faq_supporting">
            <p>
              Still have more questions? Contact our{" "}
              <a className="faq_link" href="">
                help center.
              </a>
            </p>
          </div>
        </div>
      </section>
      <section id="CTA">
        <div
          className="container CTA_content"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="left_content">
                <div className="left_heading">
                  <h2>
                    Contact us today <br /> for a FREE <br /> consultation!
                  </h2>
                </div>
                <div
                  className="left_btn"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div className="btn btn-primary CTA_btn">
                    Get a Free consultation
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 p-0">
              <div className="CTA_image">
                <img src="/CTA.avif" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container footer_container">
          <div className="row footer_row">
            <div className="col-lg-3 col-sm-3 footer_logo">
              <div className="logo">
                <img src="/svgviewer-output (1).svg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 footer_content">
              <p>
                All rights reserved <a href="#">sysbin.tech</a> | Designed and
                built with love - Copyright© 2024
              </p>
            </div>
            <div className="col-lg-3 col-sm-3 footer_button">
              <div className="btn btn-outline-primary btn-sm">
                Schedule a call
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
