"use client";
import React, { useEffect } from "react";

const FAQ = () => {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded");

      items.forEach((item) => item.setAttribute("aria-expanded", "false"));

      if (itemToggle === "false") {
        this.setAttribute("aria-expanded", "true");
      }
    }

    items.forEach((item) => item.addEventListener("click", toggleAccordion));

    // Cleanup event listeners
    return () => {
      items.forEach((item) =>
        item.removeEventListener("click", toggleAccordion)
      );
    };
  }, []);

  return (
    <div className="faq_content">
      <div className="accordion">
        {[
          {
            id: 1,
            question: "What services do you offer?",
            answer:
              "We offer a range of services to help businesses thrive in the digital age, including web development, mobile app development, digital marketing, SEO optimization, cloud computing solutions, and cybersecurity.",
          },
          {
            id: 2,
            question: "What makes your agency different from others?",
            answer:
              "We are a team of passionate and experienced professionals who are committed to delivering innovative and effective solutions tailored to our clients' specific needs. We pride ourselves on our collaborative approach, transparent communication, and commitment to exceeding expectations.",
          },
          {
            id: 3,
            question: " Do you offer maintenance services?",
            answer:
              "Yes, we offer ongoing support and maintenance to ensure your website or app runs smoothly and stays up-to-date with the latest technology and security standards.",
          },
          {
            id: 4,
            question: "How long does it take to develop a website or app?",
            answer:
              "The development timeline depends on the complexity of the project. We provide detailed timelines and milestones to keep you informed throughout the process.",
          },
        ].map(({ id, question, answer }) => (
          <div className="accordion-item" key={id}>
            <button id={`accordion-button-${id}`} aria-expanded="false">
              <span className="accordion-title">{question}</span>
              <span className="icon" aria-hidden="true"></span>
            </button>
            <div className="accordion-content">
              <p>{answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
